<template>
  <div
    class="tw-container mt-5 fb-bill-page"
    style="max-width: 1200px; margin: auto"
  >
    <NRBill></NRBill>
  </div>
</template>

<script>
// components
import NRBill from '@/components/noRegistration/NR_Bill.vue'

export default {
  components: { NRBill }
}
</script>